<template>
    <div class="page-single">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('白皮書')" left-arrow @click-left="onClickLeft">
        </van-nav-bar>
        
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            pageChange(pageNumber) {
            // 页面切换时触发的事件
            },
        },
		mounted() {
		}

    }
</script>